<template>
  <div>
    <p class="text-h5 text-h3-md text-center">Give your banana stickers here!</p>
    <is-loading :loading="loading" />
    <v-container v-if="!loading">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="4" class="d-flex align-items-center">
          <v-img src="/img/banana.gif" :aspect-ratio="1" contain :max-height="maxHeight"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" v-for="(sticker, index) in stickerData" :key="index">
          <sticker-controller :sticker="sticker" :name="sticker.owner" :score="sticker.count" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import StickerController from '@/components/bananaStickers/StickerController'
import IsLoading from '@/components/layout/Loading'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BananaSticker',
  components: {
    StickerController,
    IsLoading
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters('stickers', ['stickerData']),
    maxHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 600
        case 'xl':
          return 800
        default:
          return 1000
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('stickers', ['fetchStickerData', 'patchStickerData']),
    async init() {
      console.log('Initializing banana sticker counter.. keep your eyes peeled!')
      if (this.stickerData.length === 0) {
        await this.fetchStickerData()
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
