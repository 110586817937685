<template>
  <div v-if="loading">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate :color="color" />
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: { type: Boolean, required: true },
    color: { type: String, default: 'primary' }
  }
}
</script>

<style></style>
