<template>
  <v-card flat>
    <v-card-actions>
      <v-spacer />
      <v-btn fab color="red" dark @click="downvote()">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <div class="mx-10 text-h6 text-h4-md">{{ name }}</div>
      <v-btn fab color="green" dark @click="upvote()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-card-title class="text-h5 text-h3-md"><v-spacer />{{ score }}<v-spacer /></v-card-title>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'StickerController',
  props: {
    sticker: { type: Object, required: true },
    name: { type: String, required: true },
    score: { type: Number, required: true }
  },
  methods: {
    ...mapActions('stickers', ['patchStickerData']),
    upvote() {
      const data = new FormData()
      data.append('sticker_for', this.sticker.owner)
      data.append('vote', 'UP')
      data.append('id', this.sticker.id)

      this.patchStickerData(data)
    },
    downvote() {
      const data = new FormData()
      data.append('sticker_for', this.sticker.owner)
      data.append('vote', 'DOWN')
      data.append('id', this.sticker.id)

      this.patchStickerData(data)
    }
  }
}
</script>

<style></style>
